import axios from '@axios'

export default {
  createRecharge(params) {
    return axios.post('/api/tickets', params)
  },

  createAdsRecharge({
    id,
    type,
    amount,
  }) {
    return axios.patch(`/api/ads-accounts/${id}`, {
      type,
      amount,
    })
  },

  getHistory({ params }) {
    return axios.get('/api/transactions', { params })
  },

  getHistoryLimits({ params }) {
    return axios.get('/api/transactions', { params })
  },

  getListTicket({ params }) {
    return axios.get('/api/tickets', { params })
  },

  /*= =============== CREDIT CARD ============ */

  getListCard() {
    return axios.get('/api/billings/stripe/payment-methods')
  },

  initCard() {
    return axios.post('/api/stripe-payment/card-payment/init')
  },

  activeCard(data) {
    return axios.post('/api/stripe-payment/card-payment/active', data)
  },

  setUpIntent() {
    return axios.post('/api/billings/stripe/setup-intent')
  },

  saveCard(data) {
    return axios.post('/api/billings/stripe/payment-methods', data)
  },

  deleteCard(id) {
    return axios.delete(`/api/billings/stripe/payment-methods/${id}`)
  },

  setCardDefault(card) {
    return axios.patch(`/api/billings/stripe/payment-methods/${card.paymentMethodId}/default`, { default: true })
  },

  fulFill(data) {
    return axios.post('/api/stripe-payment/fulfill-payment', data)
  },

  reCharge(data) {
    return axios.post('/api/stripe-payment/request-payment', data)
  },

  /*= =============== END CREDIT CARD ============ */

  /*= =============== BUSD CARD ============ */
  getTransactionStatus() {
    return axios.get('/api/token-payment/transaction/mining')
  },

  requestPayment(data) {
    return axios.post('/api/token-payment/request-payment', data)
  },

  fullFillPayment({
    id,
    fromAddress,
    transactionHash,
  }) {
    return axios.post(`/api/token-payment/transaction/${id}/fulfill-payment`, {
      transactionHash,
      fromAddress,
    })
  },
  /*= =============== END BUSD CARD ============ */

  /*= =============== AIRWALLEX CARD ============ */

  createAmountAirwallex(data) {
    return axios.post('/api/airwallex-payment/request-payment', data)
  },

  /*= =============== END AIRWALLEX CARD ============ */

  /*= =============== TAZAPAY CARD ============ */

  requestTazapayPayment(data) {
    return axios.post('/api/tazapay-payments/request-payments/v3', data)
  },

  getTazapayPending() {
    return axios.get('/api/tazapay-payments/transactions/pending', {
      headers: { 'Cache-Control': 'no-cache' },
    })
  },

  /*= =============== END TAZAPAY CARD ============ */

  /*= =============== LIANLIAN CARD ============ */
  requestLianLianPayment(data) {
    return axios.post('/api/lianlian-payment/request-payment', data)
  },

  getLianlianPending() {
    return axios.get('/api/lianlian-payment/transactions/pending', {
      headers: { 'Cache-Control': 'no-cache' },
    })
  },
  /*= =============== END LIANLIAN CARD ============ */

  /*= =============== LOCAL BANK ============ */
  getListLocalBank(params) {
    return axios.get('/api/local-banks', { params })
  },

  recentLocalBankNotification() {
    return axios.get('/api/local-banks/recent-local-bank-notification')
  },

  requestPaymentPayOs(data) {
    return axios.post('/api/payos-payment/request-payment', data)
  },

  getTransactionPayOs(id) {
    return axios.get(`/api/payos-payment/transactions/${id}`)
  },

  cancelTransactionPayOs(id) {
    return axios.post(`/api/payos-payment/transactions/${id}/cancel`)
  },
  /*= =============== END LOCAL BANK ============ */

  /*= =============== AUTO TOPUP ============ */
  autoTopUpAdAccount(params) {
    return axios.patch('/api/ads-accounts/settings/auto-topup', params)
  },
  /*= =============== END AUTO TOPUP ============ */

  /*= =============== PAY FAST ============ */
  requestPayFastPayment(params) {
    return axios.post('/api/payfast-payment/request-payment', params)
  },
  /*= =============== END PAY FAST ============ */

  /*= =============== AUTO REFUND ============== */
  requestAutoRefund(params) {
    return axios.post('/api/transactions/request-refund', params)
  },

  getListUsedPaymentMethods() {
    return axios.get('/api/transactions/used-payment-methods')
  },
  /*= =============== END AUTO REFUND ============== */
}
