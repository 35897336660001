import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    listAccountHistory: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listBusinessAccountHistory: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listBusinessAccountHistoryLimit: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listBillingHistory: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listRefundUserHistory: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listCard: [],
    payoneerAccount: {
      clientRedirectUrl: '',
      email: null,
      connected: false,
      currentAccounts: null,
      request: {},
    },
    errorPayment: {
      message: '',
    },

    listTicket: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    airwallexOptions: {
      env: process.env.VUE_APP_AIRWALLEX_ENV,
      mode: process.env.VUE_APP_AIRWALLEX_MODE,
      currency: 'USD',
      intent_id: null,
      client_secret: null,
      components: [],
      successUrl: '',
      failUrl: '',
      cancelUrl: '',
    },

    tazapayRequest: {
      clientRedirectUrl: '',
    },

    tazapayPending: null,

    lianlianRequest: {
      clientRedirectUrl: '',
    },
    lianlianPending: null,
    listLocalBank: [],
    listRecentNotification: {
      loading: false,
      error: null,
      status: false,
      data: null,
    },
    payFast: null,

    listUsedPaymentMethods: [],
  },
  getters,
  mutations,
  actions,
}
