<template>
  <div>
    <div v-if="!isEcomdyPlatform">
      <img
        v-if="discordUrl"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-discord.svg')"
        alt="image"
        @click="goToDiscordUrl"
      >
      <img
        v-else-if="telegramUrl"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-telegram.svg')"
        alt="image"
        @click="goToTelegramUrl"
      >
      <img
        v-else-if="instagramUrl"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-instagram.svg')"
        alt="image"
        @click="goToInstagramUrl"
      >
      <img
        v-else-if="messengerUrl"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-message.png')"
        alt="image"
        @click.prevent="goToMessengerUrl"
      >
      <img
        v-else-if="whatsAppUrl"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-whats-app-square.svg')"
        alt="image"
        @click.prevent="goToWhatsApp"
      >
    </div>
    <div v-else>
      <img
        v-if="isExclusivePartner"
        class="discord-logo cursor-pointer"
        :src="require('@/assets/images/icons/ic-message.png')"
        alt="image"
        @click.prevent="goToMessengerUrl"
      >
    </div>
  </div>
</template>
<script>
import envMixin from '@/mixins/envMixin'

export default {
  mixins: [envMixin],
}
</script>
<style scoped lang="scss">
  .discord-logo{
    z-index: 1000;
    position: fixed;
    right: 10px;
    max-width: 60px;
    bottom: 10px;
    animation: leaves 1.8s ease-in-out infinite alternate;
    -webkit-animation: leaves 1.8s ease-in-out infinite alternate;
  }

  @keyframes leaves {
    0% {
      transform: scale(1.0);
    }
    100% {
      transform: scale(0.8);
    }
  }
</style>
