import { PaymentService, PayoneerService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async createRecharge({ commit }, params) {
    commit('CREATE_RECHARGE_REQUEST')
    try {
      await PaymentService.createRecharge(params)
      commit('CREATE_RECHARGE_SUCCESS')
    } catch (error) {
      commit('CREATE_RECHARGE_FAILURE', error)
    }
  },

  async createAdsRecharge({ commit }, params) {
    commit('CREATE_ADS_RECHARGE_REQUEST')
    try {
      await PaymentService.createAdsRecharge(params)
      commit('CREATE_ADS_RECHARGE_SUCCESS')
    } catch (error) {
      commit('CREATE_ADS_RECHARGE_FAILURE', error)
    }
  },

  async getHistory({ commit }, payload) {
    commit('GET_HISTORY_REQUEST')
    try {
      const { data } = await PaymentService.getHistory(payload)
      commit('GET_HISTORY_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_HISTORY_FAILURE', error)
    }
  },

  async getHistoryLimit({ commit }, params) {
    commit('GET_HISTORY_LIMIT_REQUEST')
    try {
      const { data } = await PaymentService.getHistoryLimits({ params })
      commit('GET_HISTORY_LIMIT_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_HISTORY_LIMIT_FAILURE', error)
    }
  },

  /*= =============== CREDIT CARD ==============   */

  async getListCard({ commit }) {
    commit('GET_LIST_CARD_REQUEST')
    try {
      const { data } = await PaymentService.getListCard()
      commit('GET_LIST_CARD_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_CARD_FAILURE', error)
    }
  },

  /*= =============== END CREDIT CARD ==============   */

  /*= =============== PAYONEER ==============   */

  async connectPayoneerAccount({ commit }, payload) {
    commit('CONNECT_PAYONEER_ACCOUNT_REQUEST')
    try {
      const { data } = await PayoneerService.connectAccount(payload)
      commit('CONNECT_PAYONEER_ACCOUNT_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('CONNECT_PAYONEER_ACCOUNT_FAILURE', error)
    }
  },

  async disconnectPayoneerAccount({ commit }) {
    commit('DISCONNECT_PAYONEER_ACCOUNT_REQUEST')
    try {
      await PayoneerService.disconnectAccount()
      commit('DISCONNECT_PAYONEER_ACCOUNT_SUCCESS')
    } catch (error) {
      commit('DISCONNECT_PAYONEER_ACCOUNT_FAILURE', error)
    }
  },

  async getPayoneerAccountBalance({ commit }) {
    commit('GET_PAYONEER_BALANCE_REQUEST')
    try {
      const { data } = await PayoneerService.getBalances()
      commit('GET_PAYONEER_BALANCE_SUCCESS', {
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_PAYONEER_BALANCE_FAILURE', error)
    }
  },

  async requestPayoneerPayment({ commit }, payload) {
    commit('REQUEST_PAYONEER_PAYMENT_REQUEST')
    try {
      const { data } = await PayoneerService.requestPayment(payload)
      commit('REQUEST_PAYONEER_PAYMENT_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('REQUEST_PAYONEER_PAYMENT_FAILURE', error)
    }
  },

  async confirmPayoneerPayment({ commit }, payload) {
    commit('CONFIRM_PAYONEER_PAYMENT_REQUEST')
    try {
      const { data } = await PayoneerService.confirmPayment(payload)
      commit('CONFIRM_PAYONEER_PAYMENT_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('CONFIRM_PAYONEER_PAYMENT_FAILURE', error)
    }
  },

  /*= =============== END PAYONEER ==============   */

  async getListTicket({ commit }, payload) {
    commit('GET_LIST_TICKET_REQUEST')
    try {
      const { data } = await PaymentService.getListTicket(payload)
      commit('GET_LIST_TICKET_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_LIST_TICKET_FAILURE', error)
    }
  },
  /*= =============== AIRWALLEX ==============   */
  async requestAirwallexPayment({ commit }, payload) {
    commit('REQUEST_AIRWALLEX_PAYMENT')
    try {
      const { data } = await PaymentService.createAmountAirwallex(payload)
      const {
        paymentId,
        clientSecret,
        currency,
        components,
        successUrl,
        failUrl,
      } = getResponse(data)?.payload
      commit('REQUEST_AIRWALLEX_PAYMENT_SUCCESS', {
        payload,
        data: {
          paymentId,
          clientSecret,
          currency,
          components,
          successUrl,
          failUrl,
        },
      })
    } catch (error) {
      commit('REQUEST_AIRWALLEX_PAYMENT_FAILURE', error)
    }
  },
  /*= =============== END AIRWALLEX ==============   */

  /*= =============== TAZAPAY ==============   */
  async requestTazapayPayment({ commit }, payload) {
    commit('REQUEST_TAZAPAY_PAYMENT')
    try {
      const { data } = await PaymentService.requestTazapayPayment(payload)
      commit('REQUEST_TAZAPAY_PAYMENT_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('REQUEST_TAZAPAY_PAYMENT_FAILURE', error)
    }
  },

  async getTazapayPending({ commit }) {
    commit('GET_TAZAPAY_PAYMENT_REQUEST')
    try {
      const { data } = await PaymentService.getTazapayPending()
      commit('GET_TAZAPAY_PAYMENT_SUCCESS', {
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_TAZAPAY_PAYMENT_FAILURE', error)
    }
  },
  /*= =============== END TAZAPAY ==============   */

  /*= =============== LIANLIAN ==============   */
  async requestLianLianPayment({ commit }, payload) {
    commit('REQUEST_LIANLIAN_PAYMENT')
    try {
      const { data } = await PaymentService.requestLianLianPayment(payload)
      commit('REQUEST_LIANLIAN_PAYMENT_SUCCESS', {
        payload,
        data: getResponse(data),
      })
    } catch (error) {
      commit('REQUEST_LIANLIAN_PAYMENT_FAILURE', error)
    }
  },

  async getLianlianPending({ commit }) {
    commit('GET_LIANLIAN_PAYMENT_REQUEST')
    try {
      const { data } = await PaymentService.getLianlianPending()
      commit('GET_LIANLIAN_PAYMENT_SUCCESS', {
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_LIANLIAN_PAYMENT_FAILURE', error)
    }
  },
  /*= =============== END LIANLIAN ==============   */

  /*= =============== LOCAL BANK ==============   */
  async getListLocalBank({ commit }, payload) {
    commit('GET_LIST_LOCAL_BANK_REQUEST')
    try {
      const { data } = await PaymentService.getListLocalBank(payload)
      commit('GET_LIST_LOCAL_BANK_SUCCESS', {
        data: getResponse(data),
      })
    } catch (error) {
      commit('GET_LIST_LOCAL_BANK_FAILURE', error)
    }
  },

  async recentLocalBankNotification({ commit }) {
    commit('RECENT_LOCAL_BANK_NOTIFICATION_REQUEST')
    try {
      const { data } = await PaymentService.recentLocalBankNotification()
      commit('RECENT_LOCAL_BANK_NOTIFICATION_SUCCESS', {
        data: getResponse(data),
      })
    } catch (error) {
      commit('RECENT_LOCAL_BANK_NOTIFICATION_FAILURE', error)
    }
  },
  /*= =============== END LOCAL BANK ==============   */

  /*= =============== AUTO TOPUP ==============   */
  async autoTopUpAdAccount({ commit }, params) {
    commit('AUTO_TOP_UP_AD_ACCOUNT_REQUEST')
    try {
      await PaymentService.autoTopUpAdAccount(params)
      commit('AUTO_TOP_UP_AD_ACCOUNT_SUCCESS')
    } catch (error) {
      commit('AUTO_TOP_UP_AD_ACCOUNT_FAILURE', error)
    }
  },
  /*= =============== END AUTO TOPUP ==============   */

  /*= =============== PAY FAST ==============   */
  async requestPayFastPayment({ commit }, params) {
    commit('PAY_FAST_REQUEST')
    try {
      const { data } = await PaymentService.requestPayFastPayment(params)
      commit('PAY_FAST_REQUEST_SUCCESS', { data: getResponse(data) })
    } catch (error) {
      commit('PAY_FAST_REQUEST_FAILURE', error)
    }
  },
  /*= =============== END PAY FAST ==============   */

  /*= =============== AUTO REFUND ============== */
  async requestAutoRefund({ commit }, params) {
    commit('REQUEST_AUTO_REFUND_REQUEST')
    try {
      const { data } = await PaymentService.requestAutoRefund(params)
      commit('REQUEST_AUTO_REFUND_SUCCESS', { data: getResponse(data) })
    } catch (error) {
      commit('REQUEST_AUTO_REFUND_FAILURE', error)
    }
  },

  async getListUsedPaymentMethods({ commit }) {
    commit('GET_LIST_USED_PAYMENT_METHOD_REQUEST')
    try {
      const { data } = await PaymentService.getListUsedPaymentMethods()
      commit('GET_LIST_USED_PAYMENT_METHOD_SUCCESS', { data: getResponse(data) })
    } catch (error) {
      commit('GET_LIST_USED_PAYMENT_METHOD_FAILURE', error)
    }
  },
  /*= =============== END AUTO REFUND ============== */
}
